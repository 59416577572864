import { Link } from "gatsby";
import { Helmet } from "react-helmet";
import Layout from "../../components/layout";

export default function Anemone() {
  return (
    <Layout>
      <Helmet>
        <title>Anemone | Nathan Pasko</title>
        <meta name="description" content="Anemone is a first-person mystery with elements of sci-fi and horror. Explore the odd world of Anemone on Mac, Windows, or Linux." />
      </Helmet>
      <div className="project-page">
        <ul className="horizontal-scroll">
          <li>
            <picture>
              <img
                src="http://avknights.com/pic/ane/4.png"
                height="497"
                width="795"
                alt="screenshot from Anemone depicting a living room illuminated only by the dim, blue glow of a television"
              />
            </picture>
          </li>
          <li>
            <picture>
              <img src="http://avknights.com/pic/ane/1.png" alt="" />
            </picture>
          </li>
          <li>
            <picture>
              <img src="http://avknights.com/pic/ane/2.png" alt="" />
            </picture>
          </li>
          <li>
            <picture>
              <img src="http://avknights.com/pic/ane/3.png" alt="" />
            </picture>
          </li>
        </ul>
        <h1>Anemone</h1>
        <p>
          The late 90s. You've arrived at the target's house and your boss'
          instructions are waiting for you. Who are you? Who's the target? What
          happened here and why? Explore the locale and uncover the underlying
          story of Anemone.
        </p>
        <p>
          Anemone is a non-traditional narrative built in interactive 3D using
          the Unity Engine. Elements of mystery, horror, and sci-fi blend in the
          ambiance of this spiritual successor to{" "}
          <Link to="/projects/cavalcade">Cavalcade</Link>.
        </p>
        <iframe frameborder="0" src="https://itch.io/embed/337229?border_width=0&amp;fg_color=000&amp;link_color=e0e0e0&amp;border_color=fff" width="206" height="165"><a href="https://avknights.itch.io/anemone">Anemone by A.V. Knights</a></iframe>
      </div>
    </Layout>
  );
}
